let resumeData = {
    imagebaseurl: 'https://rbhatia46.github.io/',
    name: 'Reinhart Paghunie',
    role: 'Fullstack Developer',
    linkedinId: '003546130',
    roleDescription:
        'I like dabbling in various parts of both frontend and backend development and like to learn about new technologies, create new things or simply play games in my free time.',
    socialLinks: [
        {
            name: 'github',
            url: 'http://github.com/reinhartP',
            className: 'fa fa-github',
        },
    ],
    aboutme:
        'I am currently in my final year as a student at California State Polytechnic University, Pomona and pursuing my degree in computer engineering from there. I am a self taught full stack web developer, currently diving deeper into computer vision and machine learning. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.',
    address: 'Chino, CA',
    website: '',
    education: [
        {
            UniversityName: 'California State Polytechnic University, Pomona',
            specialization: 'Bachelor of Science in Computer Engineering',
            MonthOfPassing: 'Dec',
            YearOfPassing: '2019',
            Achievements: '',
        },
    ],
    work: [
        {
            CompanyName: 'Some Company',
            specialization: 'Some specialization',
            MonthOfLeaving: 'Jan',
            YearOfLeaving: '2018',
            Achievements: 'Some Achievements',
        },
        {
            CompanyName: 'Some Company',
            specialization: 'Some specialization',
            MonthOfLeaving: 'Jan',
            YearOfLeaving: '2018',
            Achievements: 'Some Achievements',
        },
    ],
    skillsDescription: '',
    skills: {
        languages: [
            {
                skillname: 'C',
            },
            {
                skillname: 'C++',
            },
            {
                skillname: 'Java',
            },
            {
                skillname: 'JavaScript',
            },
            {
                skillname: 'Python',
            },
        ],
        frontend: [
            {
                skillname: 'HTML5',
            },
            {
                skillname: 'CSS',
            },
            {
                skillname: 'JavaScript',
            },
            {
                skillname: 'ES6',
            },
            {
                skillname: 'Reactjs',
            },
            {
                skillname: 'Bootstrap',
            },
        ],
        backend: [
            {
                skillname: 'NodeJS',
            },
            {
                skillname: 'MySQL',
            },
            {
                skillname: 'APIs',
            },
        ],
        cloud: [
            { skillname: 'AWS' },
            {
                skillname: 'Google Cloud',
            },
        ],
    },
    portfolio: [
        {
            name: 'Synchronized Music Site',
            description:
                'A site where you can listen to music synchronized with friends. Create an account to sync your Spotify playlists, add songs to the queue by clicking them from your synced playlists or by using the search bar which features real time suggestions.',
            imgurl: 'images/portfolio/music.jpg',
            active: true,
            url: 'https://github.com/reinhartP/Senior-Project',
        },
        {
            name: 'Mirrored Kpop Dances',
            description:
                'Watch mirrored kpop dance practice videos from the official channels and original quality. You can toggle video mirroring, loop sections of the video, and control the speed of the video with easy to use buttons under the video.',
            imgurl: 'images/portfolio/kpop.jpg',
            active: true,
            url: 'https://github.com/reinhartP/Kpop-Dance-Site',
        },
        {
            name: 'Twitch viewer',
            description:
                'Provides an enchanced Twitch viewing experience that implements community favorite emotes from BetterTwitchTV and FrankerFaceZ without the need for any browser extensions.',
            imgurl: 'images/portfolio/twitch.jpg',
            active: false,
            url: false,
        },
    ],
};

export default resumeData;
